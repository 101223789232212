<template>
  <div class="edit-achievement-widget">
    <CForm
      v-on:submit.prevent="editWidget"
      class="edit-achievement-widget-form"
      novalidate
    >
      <!--   HEADER   -->
      <CreateHeader
        :loading="loading"
        :isMainAction="!isHeaderActionsDisabled"
        :title="texts.widget.editPage.title"
        submit-title="update"
        :descriptions="descriptions"
      />
      <!--   CONTENT   -->
      <div class="content">
        <!--    FORM CONTENT    -->
        <div v-if="!isSelectTableVisible">
          <!--   THEME    -->
          <ClFormRow
            :label="texts.widget.editPage.themeLabel"
            :toolTipText="descriptions.theme"
          >
            <template #content>
              <ClSelect
                :options="themeOption"
                :placeholder="texts.widget.editPage.themePlaceholder"
                :value-prop="themeOption[0]"
                size="xxl"
                label-track="label"
                track-by="value"
                :is-press-btn="false"
                @checkValue="updateTheme"
              />
            </template>
          </ClFormRow>
          <!-- ID -->
          <ClFormRow
            :label="texts.widget.editPage.idLabel"
            :toolTipText="descriptions.id"
          >
            <template #content>
              <CInput
                :placeholder="texts.widget.editPage.idPlaceholder"
                disabled
                v-model="formData.id"
              />
            </template>
          </ClFormRow>
          <!-- NAME -->
          <ClFormRow
            :label="texts.widget.editPage.nameLabel"
            :toolTipText="descriptions.name"
          >
            <template #content>
              <CInput
                :placeholder="texts.widget.editPage.namePlaceholder"
                v-model="formData.name"
              />
            </template>
          </ClFormRow>
          <!--  Description -->
          <ClFormRow
            :label="texts.widget.editPage.descriptionLabel"
            :toolTipText="descriptions.description"
          >
            <template #content>
              <CTextarea
                :placeholder="texts.widget.editPage.descriptionPlaceholder"
                v-model="formData.description"
                add-input-classes="col-sm-12"
              />
            </template>
          </ClFormRow>
          <!--     Achievements   -->
          <ClFormRow
            :label="texts.widget.editPage.achievementsLabel"
            tooltipPosition="label"
            xl-content="8"
            :toolTipText="descriptions.achievements"
          >
            <template #content>
              <CButton
                variant="outline"
                color="dark"
                @click="toSelectTable"
                class="wizard-action-btn zq--custom-button"
              >
                {{ texts.widget.editPage.achievementsBtn }}
              </CButton>
              <ClTable
                v-if="formData.achievements.length"
                class="mt-2"
                :items="formData.achievements"
                :fields="fieldsPreview"
                @delete="handleDeleteAchievement"
              />
            </template>
          </ClFormRow>
          <!--   API KEY    -->
          <ClFormRow
            :label="texts.widget.editPage.apiLabel"
            :toolTipText="descriptions.selectApiKey"
          >
            <template #content>
              <ClSelect
                :options="apiKeyOption"
                :placeholder="texts.widget.editPage.apiPlaceholder"
                :value-prop="formData.apiKey"
                size="xxl"
                label-track="label"
                track-by="value"
                :is-press-btn="false"
                @checkValue="updateApiKey"
              />
            </template>
          </ClFormRow>
          <!--    CODE       -->
          <CRow>
            <CCol col="12">
              <CCard class="zq--wizard-card">
                <CCardBody>
                  <PreviewWidgetCode :data="previewCodeData" />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </div>
        <!--  SELECT ACH TABLE   -->
        <DepSelectTable
          v-if="isSelectTableVisible"
          @toResultTable="toResultTable"
          @updateSelectData="achSelectData"
          :selectedData="selectedData"
          :is-header="false"
          :fields="fields"
          :formData="formData"
        />
      </div>
    </CForm>
  </div>
</template>

<script>
import {mapActions} from "vuex";а
import PreviewWidgetCode from '@/shared/UI/PreviewWidgetCode';
// MOCK DATA
import mockData from '@/mock/achievementsWidgets';
// COMPONENTS
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
import CreateHeader from "@/shared/components/CreateHeader";
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import ClSelect from "@/shared/components/formComponents/ClSelect";
import DepSelectTable from "@/shared/components/DepTables/DepSelectTable";
import ClTable from './components/ClTable';
import {achievements} from "@/config/descriptions/achievements";
import {checkNullItem} from "@/helpers/checkNullItem";
import {achievementsTexts} from "@/config/pageTexts/achievements.json";

export default {
  components: {
    CreateHeader,
    ClFormRow,
    ClSelect,
    DepSelectTable,
    ClTable,
    PreviewWidgetCode
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      // MOCK
      loading: false,
      fields: [
        "select",
        "id",
        "name",
        "description",
        "created",
      ],
      previewCodeData: {
        memberId: '',
        apiKey: '',
        spaceName: 'alex',
        widgetId: ''
      },
      isSelectTableVisible: false,
      isHeaderActionsDisabled: false,
      fieldsPreview: [
        '№',
        'id',
        'name',
        "description",
        "created",
        'actions'
      ],
      apiKeyOption: [
        {
          value: 'fe893a5b16ae49cb8e36591ca7514d21',
          label: 'Key: fe893a5b16ae49cb8e36591ca7514d21 [Web]',
        },
        {
          value: '62d740be981743de88caedad41f2912d',
          label: 'Key: 62d740be981743de88caedad41f2912d [Web]',
        },
        {
          value: '969e1a4b9a314f869c60a284380768cb',
          label: 'Key: 969e1a4b9a314f869c60a284380768cb [Administrator]',
        }
      ],
      themeOption: [
        {
          value: 'defaultTheme',
          label: 'Default Theme',
        },
        {
          value: 'actionTheme',
          label: 'Action Theme',
        },
      ],
      selectedData: [],
      //
      formData: {
        theme: '',
        name: '',
        description: '',
        achievements: [],
        apiKey: '',
        // MOCK DATA
        source: mockData.source
      },
      descriptions: {
        ...achievements.widgets.edit,
      },
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('achievements', ['handleSetActiveTab', 'handleResetActiveTab']),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute, {
        name: this.$router.currentRoute.params.name
      });
      // SET ACTIVE TAB
      this.handleSetActiveTab(1);
      // TODO: Remove this
      this.previewCodeData.widgetId = this.$router.currentRoute.params.item.id;
      this.formData = {...this.formData, ...this.$router.currentRoute.params.item};
    },
    // ACH SELECT
    toSelectTable() {
      this.isHeaderActionsDisabled = true;
      this.isSelectTableVisible = true;
    },
    toResultTable() {
      this.isHeaderActionsDisabled = false;
      this.isSelectTableVisible = false;
    },
    achSelectData({selectedData}) {
      this.selectedData = checkNullItem(selectedData);
      this.formData.achievements = selectedData;
    },
    handleDeleteAchievement(id) {
      const newData = this.selectedData.filter((item) => item.id !== id);
      this.selectedData = checkNullItem(newData);
      this.formData.achievements = newData;
    },
    //
    updateTheme({value}) {
      this.formData.theme = value;
    },
    updateApiKey({value}) {
      this.formData.apiKey = value;
      this.previewCodeData.apiKey = value;
    },
    editWidget() {
      console.log(this.formData)
    }
  },
}
</script>

<style lang="scss">
.edit-achievement-widget {
  height: 100%;

  .edit-achievement-widget-form {
    height: calc(100% - 50px);

    .content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);

      .zq--wizard-card {
        .card-body {
          border: none;
        }
      }
    }
  }
}
</style>